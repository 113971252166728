.nav-left-margin {
    margin-left: 15px;
}
.audioPlaylistContainer {
    height: 300px;
    overflow-y: scroll;
}
.audioPlaylistItem {
    margin-bottom: 10px;
    color: #ffffff;
}
.audioPlaylistItemButton {
    cursor: pointer;
    width: 72%;
}
.audioPlaylistItemButtonIcon {
    padding-top: 4px;
    float: right;
}
.audioPlayerContainer {
    margin-top: 10px;
}
.audioPlayer {
    width: 82%;
    background-color: #F1F3F4;
    -webkit-transition:all 0.5s linear;
-moz-transition:all 0.5s linear;
-o-transition:all 0.5s linear;
transition:all 0.5s linear;
-moz-box-shadow: 2px 2px 4px 0px #006773;
-webkit-box-shadow:  2px 2px 4px 0px #006773;
box-shadow: 2px 2px 4px 0px #006773;
-moz-border-radius:7px 7px 7px 7px ;
-webkit-border-radius:7px 7px 7px 7px ;
border-radius:7px 7px 7px 7px ;
}
/* width */
::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #43BDD4;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
/* .audioPlaylistItem {
    border: 2px solid grey;
    padding-left: 26px;
    padding-top: 12px;
    padding-bottom: 0px;
    width: 72%;
    border-radius: 25px;
    margin-bottom: 5px;
}
.audioPlaylistItem :hover {
    
} */

.assignigDivTables {
    max-height: 200px;
}